import { render, staticRenderFns } from "./depositTemplate1.vue?vue&type=template&id=457a4b93&scoped=true&"
import script from "./depositTemplate1.vue?vue&type=script&lang=js&"
export * from "./depositTemplate1.vue?vue&type=script&lang=js&"
import style0 from "./depositTemplate1.vue?vue&type=style&index=0&id=457a4b93&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457a4b93",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VContainer,VDivider,VForm,VImg,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('457a4b93')) {
      api.createRecord('457a4b93', component.options)
    } else {
      api.reload('457a4b93', component.options)
    }
    module.hot.accept("./depositTemplate1.vue?vue&type=template&id=457a4b93&scoped=true&", function () {
      api.rerender('457a4b93', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/deposit/template/depositTemplate1.vue"
export default component.exports