var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-1 pt-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "d-flex align-center px-5",
                      class: "card--" + _vm.theme,
                      attrs: { height: "48" }
                    },
                    [
                      _c("v-card-text", { staticClass: "pa-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center main d-flex align-center justify-space-between"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "main__description font-weight-bold",
                                class: [
                                  _vm.theme === "dark"
                                    ? "white--text"
                                    : "primary--text"
                                ]
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.mainWallet")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "main__wallet",
                                class: [
                                  _vm.theme === "dark"
                                    ? "white--text"
                                    : "primary--text"
                                ]
                              },
                              [
                                _c("span", { staticClass: "subtitle-1" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.currencySymbol_) + " "
                                  )
                                ]),
                                _c("span", { staticClass: "text-h5" }, [
                                  _vm._v(_vm._s(_vm.main))
                                ])
                              ]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "px-1 pt-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "d-flex align-center px-5",
                      class: "card--" + _vm.theme,
                      attrs: { height: "48" }
                    },
                    [
                      _c("v-card-text", { staticClass: "pa-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center main d-flex align-center justify-space-between"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "main__description text--text font-weight-bold d-flex align-center justify-center",
                                on: {
                                  click: function($event) {
                                    _vm.dialog = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.lockWallet")) +
                                    " "
                                ),
                                _c("Icon", {
                                  staticClass:
                                    "ml-1 cursor-pointer primary--text",
                                  attrs: {
                                    data: require("@icon/help.svg"),
                                    width: "16",
                                    height: "16"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "main__wallet text--text" },
                              [
                                _c("span", { staticClass: "subtitle-1" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.currencySymbol_) + " "
                                  )
                                ]),
                                _c("span", { staticClass: "text-h5" }, [
                                  _vm._v(_vm._s(_vm.locked))
                                ])
                              ]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Alert",
        { attrs: { dialog: _vm.dialog, width: 280 } },
        [
          _c(
            "div",
            {
              staticClass: "title--text subtitle-1",
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v(" " + _vm._s(_vm.$t("myCenter.lockWallet")) + " ")]
          ),
          _c(
            "div",
            { staticClass: "px-6 text", attrs: { slot: "text" }, slot: "text" },
            [_vm._v(" " + _vm._s(_vm.$t("myCenter.lockWalletInfo")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.dialog = false
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.gotIt")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }