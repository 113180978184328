var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-1 pt-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { color: "card1", height: "80" } },
                    [
                      _c("v-card-text", { staticClass: "px-0" }, [
                        _c("div", { staticClass: "text-center main" }, [
                          _c("div", { staticClass: "main__wallet" }, [
                            _c(
                              "span",
                              { staticClass: "primary--text subtitle-1" },
                              [_vm._v(" " + _vm._s(_vm.currencySymbol_) + " ")]
                            ),
                            _c("span", { staticClass: "title--text text-h5" }, [
                              _vm._v(_vm._s(_vm.player.balance))
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "main__description primary--text font-weight-bold caption"
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.player.name) + " ")
                              ])
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.isLiffMode
                ? _c(
                    "v-col",
                    { staticClass: "px-1 pt-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { color: "card1", height: "80" } },
                        [
                          _c("v-card-text", { staticClass: "px-0" }, [
                            _c("div", { staticClass: "text-center main" }, [
                              _c("div", { staticClass: "main__wallet" }, [
                                _c(
                                  "span",
                                  { staticClass: "secondary--text subtitle-1" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.currencySymbol_) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "title--text text-h5" },
                                  [_vm._v(_vm._s(_vm.locked_wallet.balance))]
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "main__description secondary--text font-weight-bold d-flex align-center justify-center caption",
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.locked_wallet.name) + " "
                                  ),
                                  _c("Icon", {
                                    staticClass:
                                      "ml-1 cursor-pointer link--text",
                                    attrs: {
                                      data: require("@icon/help.svg"),
                                      width: "16",
                                      height: "16"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    { staticClass: "px-1 pt-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { color: "card1", height: "80" } },
                        [
                          _c("v-card-text", { staticClass: "px-0" }, [
                            _c("div", { staticClass: "text-center main" }, [
                              _c("div", { staticClass: "main__wallet" }, [
                                _c(
                                  "span",
                                  { staticClass: "secondary--text subtitle-1" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.currencySymbol_) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "title--text text-h5" },
                                  [_vm._v(_vm._s(_vm.free.balance))]
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "main__description secondary--text font-weight-bold d-flex align-center justify-center caption"
                                },
                                [_vm._v(" " + _vm._s(_vm.free.name) + " ")]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Alert",
        { attrs: { dialog: _vm.dialog, width: 280 } },
        [
          _c(
            "div",
            {
              staticClass: "title--text subtitle-1",
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v(" " + _vm._s(_vm.$t("myCenter.lockWallet")) + " ")]
          ),
          _c(
            "div",
            { staticClass: "px-6 text", attrs: { slot: "text" }, slot: "text" },
            [_vm._v(" " + _vm._s(_vm.$t("myCenter.lockWalletInfo")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.dialog = false
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.gotIt")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }